export const prepareHistory = async (
  backendUrl: string,
  backendVersion: string,
) => {
  const { fetchCSRFToken } = authenticationFetch();
  const csrfToken = await fetchCSRFToken();
  const headers: Record<string, string> = {
    "X-CSRFToken": csrfToken,
  };

  return {
    historyURL: `${backendUrl}/api/${backendVersion}/history/?limit=50`,
    historyHeaders: headers,
  };
};

export const prepareExport = async (
  backendUrl: string,
  backendVersion: string,
  token: string,
) => {
  const { fetchCSRFToken } = authenticationFetch();
  const csrfToken = await fetchCSRFToken();
  const headers: Record<string, string> = {
    "X-CSRFToken": csrfToken,
  };

  return {
    exportURL: `${backendUrl}/api/${backendVersion}/export/${token}/`,
    exportHeaders: headers,
  };
};

export const prepareTemplates = (
  backendUrl: string,
  backendVersion: string,
) => {
  return {
    templatesURL: `${backendUrl}/api/${backendVersion}/templates/`,
  };
};

export const prepareUserInformation = async (
  backendUrl: string,
  backendVersion: string,
) => {
  const { fetchCSRFToken } = authenticationFetch();
  const csrfToken = await fetchCSRFToken();
  const headers: Record<string, string> = {
    "X-CSRFToken": csrfToken,
  };
  return {
    meURL: `${backendUrl}/api/${backendVersion}/authentication/me/`,
    meHeaders: headers,
  };
};

export const prepareVersion = (backendUrl: string, backendVersion: string) => {
  return {
    versionURL: `${backendUrl}/api/${backendVersion}/version`,
  };
};
