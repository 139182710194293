import { defineStore } from "pinia";
import type {
  ITemplate,
  IUser,
  IHistoryRow,
} from "~/types/generalData.interface";

export const useMainStore = defineStore("main", {
  state: () => ({
    companyTemplates: [] as ITemplate[],
    generations: [] as IHistoryRow[],
    quota: 0 as number,
    provider: "",
    companyColor: "arneo",
    firstConnection: false,
    appVersion: "",
    user: { firstName: "", lastName: "", trigram: ""},
  }),
  actions: {
    setHistory(generations: IHistoryRow[]) {
      this.generations = generations;
    },
    setAppVersion(value: string) {
      this.appVersion = value;
    },
    setUserInfo(userData: IUser) {
      this.user.firstName = userData.firstName;
      this.user.lastName = userData.lastName;
      this.user.trigram = userData.trigram;
      this.provider = userData.provider;
      this.quota = userData.quota;
      this.companyTemplates = userData.templates;
      this.firstConnection = userData.firstConnection;
    },
    setAvailableTemplates(templates: ITemplate[]) {
      this.companyTemplates = templates;
    },
    setCompanyColor(value: string) {
      this.companyColor = value;
    },
  },
});
