import type {
  IHistoryRow,
  IUser,
  ITemplate,
} from "../types/generalData.interface";

export function generalFetch() {
  const uxStore = useUXStore();
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  const fetchUserInformation = async (
    url: string,
    headers: Record<string, string>,
  ) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: headers,
      });

      const errorValue: IUser = {
        firstName: "",
        lastName: "",
        quota: 0,
        templates: [],
        trigram: "",
      };

      if (!response.ok) {
        uxStore.setAlert(true, {
          type: "error",
          title: t("commons.alerts.errorProfile.titleProfile"),
          subtitle: t("commons.alerts.errorProfile.subProfile"),
          ctaTextRight: {
            label: t("commons.alerts.ctaHome.label"),
            ariaLabel: t("commons.alerts.ctaHome.ariaLabel"),
          },
          doubleCTA: false,
        });
        return errorValue;
      }

      const data = await response.json();

      const company = data.Company;
      const templates = data.Templates;
      const finalTemplates: ITemplate[] = templates.map((template: any) => {
        return {
          template_id: template?.Id as string,
          companyName: template?.Name as string,
        };
      });

      const tmp_tri =
        data.FirstName === "" || data.LastName === ""
          ? ""
          : data.FirstName.charAt(0).toUpperCase() +
            data.LastName.substring(0, 2).toUpperCase();

      const userInfo: IUser = {
        firstName: data.FirstName,
        lastName: data.LastName,
        provider: company.AiProvider,
        quota: data.Quota === null ? 0 : (data.Quota as number),
        templates: finalTemplates,
        trigram: tmp_tri,
        firstConnection: data.FirstConnection,
      };

      return userInfo;
    } catch (error) {
      console.error("ERROR GETTING USER INFORMATION -> ", error);
    }
  };

  const fetchHistory = async (url: string, headers: Record<string, string>) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: headers,
      });
      const values = new Array<IHistoryRow>();
      if (!response.ok) {
        return values;
      }
      const data = await response.json();
      data.history.forEach((element: object) => {
        const obj: IHistoryRow = valdidateDates(element);
        values.push(obj);
      });
      return values;
    } catch (error) {
      console.error("ERROR GET HISTORY -> ", error);
      return new Array<IHistoryRow>();
    }
  };

  const fetchVersion = async (url: string) => {
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      let appVersion = "";
      if (!response.ok) {
        return appVersion;
      }
      const data = await response.json();
      appVersion = data.app_version;
      return appVersion;
    } catch (error) {
      console.error("ERROR GET VERSION -> ", error);
    }
  };

  return {
    fetchHistory,
    fetchUserInformation,
    fetchVersion,
  };
}
